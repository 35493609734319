

import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BehaviorSubject, lastValueFrom } from "rxjs";
import { environment } from 'src/environments/environment';
import { SocketioService } from '../socket/socketio.service';


@Injectable({
  providedIn: 'root',
})
export class UserService {
  auth$: BehaviorSubject<any> = new BehaviorSubject(false);

  showValidateTel$: BehaviorSubject<any> = new BehaviorSubject(false);
  tlfValidates$: BehaviorSubject<any> = new BehaviorSubject({});
  tlfValidatesWfReq$: BehaviorSubject<any> = new BehaviorSubject([]);
  nav$: BehaviorSubject<any> = new BehaviorSubject(true);
  take$: BehaviorSubject<any> = new BehaviorSubject(false);
  biometria$: BehaviorSubject<any> = new BehaviorSubject(null);

  private url_la: any;
  private url_OB: any;
  private url_TRUSTX: any;

  env: any = environment;
  private auth: any;
  private token: any;
  private user: any;
  private tokenName: any;
  constructor(public httpClient: HttpClient) {
    this.url_la = this.env.BASE_LA;
    this.url_OB = this.env.ONBOARDING_LA;
    this.url_TRUSTX = this.env.TRUSTX;


  }

  createUser = async (body: any) => {
    const headers = { 'content-type': 'application/json' };
    const request = {};
    try {
      return lastValueFrom(
        this.httpClient.post<any>(`${this.baseUrl}/api/person/user`, body, {
          headers: headers,
          params: request,
        })
      );
    } catch (error) {
      console.error(error);
    }
  };

  createPerson = async (body: any) => {
    const headers = { 'content-type': 'application/json' };
    const request = {};
    try {
      return lastValueFrom(
        this.httpClient.post<any>(`${this.baseUrl}/api/person`, body, {
          headers: headers,
          params: request,
        })
      );
    } catch (error) {
      console.error(error);
    }
  };

  saveTokenNotifications = async (body: any) => {
    const headers = { 'content-type': 'application/json' };
    const request = {};
    try {
      return lastValueFrom(
        this.httpClient.post<any>(
          `${this.baseUrl}/api/person/save-token`,
          body,
          { headers: headers, params: request }
        )
      );
    } catch (error) {
      console.error(error);
    }
  };

  getPersonByIdentifier = async (body: any) => {
    const headers = { 'content-type': 'application/json' };
    const request = {};
    try {
      return lastValueFrom(
        this.httpClient.post<any>(`${this.baseUrl}/api/person/identity`, body, {
          headers: headers,
          params: request,
        })
      );
    } catch (error) {
      console.error(error);
    }
  };

  getPersonbyCode = async (body: any) => {
    const headers = { 'content-type': 'application/json' };
    const request = {};
    try {
      return lastValueFrom(
        this.httpClient.post<any>(`${this.baseUrl}/api/person/code`, body, {
          headers: headers,
          params: request,
        })
      );
    } catch (error) {
      console.error(error);
    }
  };

  reconciledPayment = async (body: any) => {
    const headers = { 'content-type': 'application/json' };
    const request = {};
    try {
      const resp = lastValueFrom(
        this.httpClient.post<any>(
          `${this.baseUrl}/api/invoice-credit/reconciled_payment_invoces`,
          body,
          { headers: headers, params: request }
        )
      );
      return resp;
    } catch (error) {
      console.error(error);
    }
  };

  cancelPayment = async (body: any) => {
    const headers = { 'content-type': 'application/json' };
    const request = {};
    try {
      const resp = lastValueFrom(
        this.httpClient.post<any>(
          `${this.baseUrl}/api/invoice-credit/cancel_payment`,
          body,
          { headers: headers, params: request }
        )
      );
      return resp;
    } catch (error) {
      console.error(error);
    }
  };

  paymentRollback = async (body: any) => {
    const headers = { 'content-type': 'application/json' };
    const request = {};
    try {
      const resp = lastValueFrom(
        this.httpClient.post<any>(
          `${this.baseUrl}/api/invoice-credit/rollback_payment`,
          body,
          { headers: headers, params: request }
        )
      );
      return resp;
    } catch (error) {
      console.error(error);
    }
  };

  getSection_transaction_by_person = async (params: any) => {
    const headers = { 'content-type': 'application/json' };
    const request = params;

    try {
      return lastValueFrom(
        this.httpClient.get<any>(
          this.baseUrl + '/api/person/section_transaction_by_person',
          { headers: headers, params: request }
        )
      );
    } catch (error) {
      console.error(error);
    }
  };

  getInstallMents = async (params: any) => {
    const headers = { 'content-type': 'application/json' };
    const request = params;

    try {
      return lastValueFrom(
        this.httpClient.get<any>(
          this.baseUrl + '/api/invoice-credit/installMents',
          { headers: headers, params: request }
        )
      );
    } catch (error) {
      console.error(error);
    }
  };

  getPeople_approved = async (params: any = {}) => {
    const headers = { 'content-type': 'application/json' };
    const request = params;

    try {
      return lastValueFrom(
        this.httpClient.get<any>(this.baseUrl + '/api/person/people_approved', {
          headers: headers,
          params: request,
        })
      );
    } catch (error) {
      console.error(error);
    }
  };

  getPeople_status = async (params: any = {}) => {
    const headers = { 'content-type': 'application/json' };
    const request = params;

    try {
      return lastValueFrom(
        this.httpClient.get<any>(this.baseUrl + '/api/approve/app_flow_statistics', {
          headers: headers,
          params: request,
        })
      );
    } catch (error) {
      console.error(error);
    }
  };



  getPeople = async (params: any = {}) => {
    const headers = { 'content-type': 'application/json' };
    const request = params;

    try {
      return lastValueFrom(
        this.httpClient.get<any>(this.baseUrl + '/api/person/people', {
          headers: headers,
          params: request,
        })
      );
    } catch (error) {
      console.error(error);
    }
  };

  getUsers = async (params: any = {}) => {
    const headers = { 'content-type': 'application/json' };
    const request = params;

    try {
      return lastValueFrom(
        this.httpClient.get<any>(this.baseUrl + '/api/person/users', {
          headers: headers,
          params: request,
        })
      );
    } catch (error) {
      console.error(error);
    }
  };

  getPersonMaritalStatus = async () => {
    const headers = { 'content-type': 'application/json' };
    const request = {};

    try {
      return lastValueFrom(
        this.httpClient.get<any>(this.baseUrl + '/api/person/marital_status', {
          headers: headers,
          params: request,
        })
      );
    } catch (error) {
      console.error(error);
    }
  };

  getBase_amount = async () => {
    const headers = { 'content-type': 'application/json' };
    const request = {};

    try {
      return lastValueFrom(
        this.httpClient.get<any>(this.baseUrl + '/api/person/base_amount', {
          headers: headers,
          params: request,
        })
      );
    } catch (error) {
      console.error(error);
    }
  };

  account_payment = async () => {
    const headers = { 'content-type': 'application/json' };
    const request = {};

    try {
      return lastValueFrom(
        this.httpClient.get<any>(
          this.baseUrl + '/api/invoice-credit/account_payment',
          { headers: headers, params: request }
        )
      );
    } catch (error) {
      console.error(error);
    }
  };

  payment_methods = async () => {
    const headers = { 'content-type': 'application/json' };
    const request = {};

    try {
      return lastValueFrom(
        this.httpClient.get<any>(
          this.baseUrl + '/api/payment_method',
          { headers: headers, params: request }
        )
      );
    } catch (error) {
      console.error(error);
    }
  };

  getSectionTransactions = async (params: any) => {
    const headers = { 'content-type': 'application/json' };
    const request = params;

    try {
      return lastValueFrom(
        this.httpClient.get<any>(
          this.baseUrl + '/api/person/section_transaction',
          { headers: headers, params: request }
        )
      );
    } catch (error) {
      console.error(error);
    }
  };

  getSectionTransactionsProgres = async (body: any) => {
    const headers = { 'content-type': 'application/json' };
    const request = {};
    try {
      return lastValueFrom(
        this.httpClient.post<any>(
          `${this.baseUrl}/api/person/section_transaction_progress`,
          body,
          { headers: headers, params: request }
        )
      );
    } catch (error) {
      console.error(error);
    }
  };

  getPersonTypeIdentity = async () => {
    const headers = { 'content-type': 'application/json' };
    const request = {};

    try {
      return lastValueFrom(
        this.httpClient.get<any>(this.baseUrl + '/api/person/type_identity', {
          headers: headers,
          params: request,
        })
      );
    } catch (error) {
      console.error(error);
    }
  };

  getPersonCodeIdentity = async () => {
    const headers = { 'content-type': 'application/json' };
    const request = {};

    try {
      return lastValueFrom(
        this.httpClient.get<any>(this.baseUrl + '/api/person/code_identity', {
          headers: headers,
          params: request,
        })
      );
    } catch (error) {
      console.error(error);
    }
  };

  get baseUrl() {
    return this.url_la;
  }

  getDataBuro = async (body: any) => {
    const headers = { 'content-type': 'application/json' };
    const request = {};
    try {
      return lastValueFrom(
        this.httpClient.post<any>(`${this.baseUrl}/api/buro/score`, body, {
          headers: headers,
          params: request,
        })
      );
    } catch (error) {
      console.error(error);
    }
  };

  send_responsea_approvated = async (body: any) => {
    const headers = { 'content-type': 'application/json' };
    const request = {};
    try {
      return lastValueFrom(
        this.httpClient.post<any>(
          `${this.baseUrl}/api/buro/send_responsea_approvated`,
          body,
          { headers: headers, params: request }
        )
      );
    } catch (error) {
      console.error(error);
    }
  };

  getExistBuro = async (body: any = {}) => {
    const headers = { 'content-type': 'application/json' };
    const request = {};

    try {
      return lastValueFrom(
        this.httpClient.post<any>(`${this.baseUrl}/api/buro/exist`, body, {
          headers: headers,
          params: request,
        })
      );
    } catch (error) {
      console.error(error);
    }
  };

  getScorePsicometrico = async (body: any = {}) => {
    const headers = { 'content-type': 'application/json' };
    const request = {};

    try {
      return lastValueFrom(
        this.httpClient.post<any>(
          `${this.baseUrl}/api/buro/psicometrico`,
          body,
          { headers: headers, params: request }
        )
      );
    } catch (error) {
      console.error(error);
    }
  };

  setStatus_psicometrico = async (body: any) => {
    const headers = { 'content-type': 'application/json' };
    const request = {};
    try {
      return lastValueFrom(
        this.httpClient.post<any>(
          `${this.baseUrl}/api/person/status_psicometrico`,
          body,
          { headers: headers, params: request }
        )
      );
    } catch (error) {
      console.error(error);
    }
  };

  setStart_psicometrico = async (body: any) => {
    const headers = { 'content-type': 'application/json' };
    const request = {};
    try {
      return lastValueFrom(
        this.httpClient.post<any>(
          `${this.baseUrl}/api/buro/start_psicometrico`,
          body,
          { headers: headers, params: request }
        )
      );
    } catch (error) {
      console.error(error);
    }
  };

  clientQuota = async (body: any) => {
    const headers = { 'content-type': 'application/json' };
    const request = {};
    try {
      return lastValueFrom(
        this.httpClient.post<any>(
          `${this.baseUrl}/api/invoice-credit/client-quota`,
          body,
          { headers: headers, params: request }
        )
      );
    } catch (error) {
      console.error(error);
    }
  };

  clientQuotaBasic = async (body: any) => {
    const headers = { 'content-type': 'application/json' };
    const request = {};
    try {
      return lastValueFrom(
        this.httpClient.post<any>(
          `${this.baseUrl}/api/invoice-credit/client-quota-basic`,
          body,
          { headers: headers, params: request }
        )
      );
    } catch (error) {
      console.error(error);
    }
  };

  clientCountCreditPending = async (body: any) => {
    const headers = { 'content-type': 'application/json' };
    const request = {};
    try {
      return lastValueFrom(
        this.httpClient.post<any>(
          `${this.baseUrl}/api/invoice-credit/client-count-credit-pending`,
          body,
          { headers: headers, params: request }
        )
      );
    } catch (error) {
      console.error(error);
    }
  };

  creditSave = async (body: any) => {
    const headers = { 'content-type': 'application/json' };
    const request = {};
    try {
      const resp = lastValueFrom(
        this.httpClient.post<any>(
          `${this.baseUrl}/api/invoice-credit/client-invoice-credit`,
          body,
          { headers: headers, params: request }
        )
      );
      return resp;
    } catch (error) {
      console.error(error);
    }
  };

  clientQuotaReset = async (body: any) => {
    const headers = { 'content-type': 'application/json' };
    const request = {};
    try {
      const resp = lastValueFrom(
        this.httpClient.post<any>(
          `${this.baseUrl}/api/invoice-credit/reset-credit-internal`,
          body,
          { headers: headers, params: request }
        )
      );

      // const resp = lastValueFrom(this.httpClient.post<any>(`${this.baseUrl}/api/invoice-credit/reset-invoice-all`, body, { headers: headers, params: request }));
      return resp;
    } catch (error) {
      console.error(error);
    }
  };

  clientQBuroReset = async (body: any) => {
    const headers = { 'content-type': 'application/json' };
    const request = {};
    try {
      const resp = lastValueFrom(
        this.httpClient.post<any>(
          `${this.baseUrl}/api/invoice-credit/reset-buro-all`,
          body,
          { headers: headers, params: request }
        )
      );
      return resp;
    } catch (error) {
      console.error(error);
    }
  };

  getSendsms = async (request: any) => {
    const headers = { 'content-type': 'application/json' };
    const body = {};
    try {
      return lastValueFrom(
        this.httpClient.post<any>(
          `https://sms.creditotal.online/sendsms`,
          body,
          { headers: headers, params: request }
        )
      );
    } catch (error) {
      console.error(error);
    }
  };

  getVerificate = async (request: any) => {
    const headers = { 'content-type': 'application/json' };
    const body = {};
    try {
      return lastValueFrom(
        this.httpClient.get<any>(`https://sms.creditotal.online/verificate`, {
          headers: headers,
          params: request,
        })
      );
    } catch (error) {
      console.error(error);
    }
  };

  sendsmsGeneral = async (body: any) => {
    const headers = { 'content-type': 'application/json' };
    const request = {};
    try {
      return lastValueFrom(
        this.httpClient.post<any>(
          `https://sms.creditotal.online/sendsms/general`,
          body,
          { headers: headers, params: request }
        )
      );
    } catch (error) {
      console.error(error);
    }
  };

  contact = async (body: any) => {
    const headers = { 'content-type': 'application/json' };
    const request = {};
    try {
      return lastValueFrom(
        this.httpClient.post<any>(`${this.baseUrl}/api/person/contact`, body, {
          headers: headers,
          params: request,
        })
      );
    } catch (error) {
      console.error(error);
    }
  };

  verificatedEmailPost = async (body: any) => {
    const headers = { 'content-type': 'application/json' };
    const request = {};
    try {
      return lastValueFrom(
        this.httpClient.post<any>(
          `${this.baseUrl}/api/person/email_verifcate`,
          body,
          { headers: headers, params: request }
        )
      );
    } catch (error) {
      console.error(error);
    }
  };

  personApproveConditionsPost = async (body: any) => {
    const headers = { 'content-type': 'application/json' };
    const request = {};

    try {
      return lastValueFrom(
        this.httpClient.post<any>(
          `${this.baseUrl}/api/person/approve_conditions`,
          body,
          { headers: headers, params: request }
        )
      );
    } catch (error) {
      console.error(error);
    }
  };

  listClientInvoiceCredits = async (body: any) => {
    const headers = { 'content-type': 'application/json' };
    const request = {};
    try {
      return lastValueFrom(
        this.httpClient.post<any>(
          `${this.baseUrl}/api/invoice-credit/list_client_invoice_credits2`,
          body,
          { headers: headers, params: request }
        )
      );
    } catch (error) {
      console.error(error);
    }
  };

  getIsNeteable = async (body: any) => {
    const headers = { 'content-type': 'application/json' };
    const request = {};
    try {
      return lastValueFrom(
        this.httpClient.post<any>(
          `${this.baseUrl}/api/invoice-credit/is_netable`,
          body,
          { headers: headers, params: request }
        )
      );
    } catch (error) {
      console.error(error);
    }
  };

  register_netable_payment = async (body: any) => {
    const headers = { 'content-type': 'application/json' };
    const request = {};
    try {
      return lastValueFrom(
        this.httpClient.post<any>(
          `${this.baseUrl}/api/invoice-credit/register_netable_payment`,
          body,
          { headers: headers, params: request }
        )
      );
    } catch (error) {
      console.error(error);
    }
  };

  listInvoiceCredits = async (body: any = {}, request: any = {}) => {
    const headers = { 'content-type': 'application/json' };
    try {
      return lastValueFrom(
        this.httpClient.post<any>(
          `${this.baseUrl}/api/invoice-credit/list_invoice_credits`,
          body,
          { headers: headers, params: request }
        )
      );
    } catch (error) {
      console.error(error);
    }
  };

  listClientPaymentsCredits = async (body: any) => {
    const headers = { 'content-type': 'application/json' };
    const request = {};
    try {
      return lastValueFrom(
        this.httpClient.post<any>(
          `${this.baseUrl}/api/invoice-credit/person_payments`,
          body,
          { headers: headers, params: request }
        )
      );
    } catch (error) {
      console.error(error);
    }
  };

  listPaymentsCredits = async (body: any = {}, request: any = {}) => {
    const headers = { 'content-type': 'application/json' };
    try {
      return lastValueFrom(
        this.httpClient.post<any>(
          `${this.baseUrl}/api/invoice-credit/payments`,
          body,
          { headers: headers, params: request }
        )
      );
    } catch (error) {
      console.error(error);
    }
  };

  getPersonInstallmentsInvoiceCredits = async (body: any) => {
    const headers = { 'content-type': 'application/json' };
    const request = {};
    try {
      return lastValueFrom(
        this.httpClient.post<any>(
          `${this.baseUrl}/api/invoice-credit/list_client_installment_invoice_credits`,
          body,
          { headers: headers, params: request }
        )
      );
    } catch (error) {
      console.error(error);
    }
  };

  getPersonInstallmentpayment = async (body: any) => {
    const headers = { 'content-type': 'application/json' };
    const request = {};
    try {
      return lastValueFrom(
        this.httpClient.post<any>(
          `${this.baseUrl}/api/invoice-credit/installment_client_payment`,
          body,
          { headers: headers, params: request }
        )
      );
    } catch (error) {
      console.error(error);
    }
  };

  getInstallMentsFN = async (params: any) => {
    const headers = { 'content-type': 'application/json' };
    const request = params;

    try {
      return lastValueFrom(
        this.httpClient.get<any>(
          this.baseUrl + '/api/invoice-credit/installMentsFN',
          { headers: headers, params: request }
        )
      );
    } catch (error) {
      console.error(error);
    }
  };

  getInstallMents_statusFN = async (params: any) => {
    const headers = { 'content-type': 'application/json' };
    const request = params;

    try {
      return lastValueFrom(
        this.httpClient.get<any>(
          this.baseUrl + '/api/invoice-credit/installMents_statusFN',
          { headers: headers, params: request }
        )
      );
    } catch (error) {
      console.error(error);
    }
  };

  getAllInvoicesReportGet = async (params: any) => {
    const headers = { 'content-type': 'application/json' };
    const request = params;

    try {
      return lastValueFrom(
        this.httpClient.get<any>(
          this.baseUrl + '/api/invoice-credit/all_invoices_report',
          { headers: headers, params: request }
        )
      );
    } catch (error) {
      console.error(error);
    }
  };

  allPaymentsReportGet = async (params: any) => {
    const headers = { 'content-type': 'application/json' };
    const request = params;

    try {
      return lastValueFrom(
        this.httpClient.get<any>(
          this.baseUrl + '/api/invoice-credit/all_payments_report',
          { headers: headers, params: request }
        )
      );
    } catch (error) {
      console.error(error);
    }
  };

  allclientsReportGet = async (params: any) => {
    const headers = { 'content-type': 'application/json' };
    const request = params;

    try {
      return lastValueFrom(
        this.httpClient.get<any>(
          this.baseUrl + '/api/person/all_person_report',
          { headers: headers, params: request }
        )
      );
    } catch (error) {
      console.error(error);
    }
  };

  clientsdomicReportGet = async (params: any) => {
    const headers = { 'content-type': 'application/json' };
    const request = params;

    try {
      return lastValueFrom(
        this.httpClient.get<any>(
          this.baseUrl + '/api/person/accounts_person_report',
          { headers: headers, params: request }
        )
      );
    } catch (error) {
      console.error(error);
    }
  };

  dist_paymentsReportGet = async (params: any) => {
    const headers = { 'content-type': 'application/json' };
    const request = params;

    try {
      return lastValueFrom(
        this.httpClient.get<any>(
          this.baseUrl + '/api/invoice-credit/all_approved_payments_report',
          { headers: headers, params: request }
        )
      );
    } catch (error) {
      console.error(error);
    }
  };


  clientEstadisticReportGet = async (params: any) => {
    const headers = { 'content-type': 'application/json' };
    const request = params;

    try {
      return lastValueFrom(
        this.httpClient.get<any>(
          this.baseUrl + '/api/invoice-credit/client_stadictic_report',
          { headers: headers, params: request }
        )
      );
    } catch (error) {
      console.error(error);
    }
  };


  sapPaymentsReportGet = async (params: any) => {
    const headers = { 'content-type': 'application/json' };
    const request = params;

    try {
      return lastValueFrom(
        this.httpClient.get<any>(
          this.baseUrl + '/api/invoice-credit/sap_payments_report',
          { headers: headers, params: request }
        )
      );
    } catch (error) {
      console.error(error);
    }
  };

  sapGirosReportGet = async (params: any) => {
    const headers = { 'content-type': 'application/json' };
    const request = params;

    try {
      return lastValueFrom(
        this.httpClient.get<any>(
          this.baseUrl + '/api/invoice-credit/sap_giros_report',
          { headers: headers, params: request }
        )
      );
    } catch (error) {
      console.error(error);
    }
  };

  traceDocumentsGet = async (params: any) => {
    const headers = { 'content-type': 'application/json' };
    const request = params;
debugger;
    try {
      return lastValueFrom(
        this.httpClient.get<any>(
          this.baseUrl + '/api/invoice-credit/trace_documents',
          { headers: headers, params: request }
        )
      );
    } catch (error) {
      console.error(error);
    }
  };

  getshops = async (params: any = {}) => {
    const headers = { 'content-type': 'application/json' };
    const request = params;

    try {
      return lastValueFrom(
        this.httpClient.get<any>(this.baseUrl + '/api/invoice-credit/shop', {
          headers: headers,
          params: request,
        })
      );
    } catch (error) {
      console.error(error);
    }
  };

  payment_sub_status = async (params: any = {}) => {
    const headers = { 'content-type': 'application/json' };
    const request = params;

    try {
      return lastValueFrom(
        this.httpClient.get<any>(
          this.baseUrl + '/api/invoice-credit/payment_sub_status',
          { headers: headers, params: request }
        )
      );
    } catch (error) {
      console.error(error);
    }
  };

  getStadistic = async (body: any = {}) => {
    const headers = { 'content-type': 'application/json' };
    const request = {};
    try {
      return lastValueFrom(
        this.httpClient.post<any>(
          `${this.baseUrl}/api/invoice-credit/stadistic`,
          body,
          { headers: headers, params: request }
        )
      );
    } catch (error) {
      console.error(error);
    }
  };

  ejecutives_peoples = async (params: any = {}) => {
    const headers = { 'content-type': 'application/json' };
    const request = params;

    try {
      return lastValueFrom(
        this.httpClient.get<any>(
          this.baseUrl + '/api/person/ejecutives_peoples',
          { headers: headers, params: request }
        )
      );
    } catch (error) {
      console.error(error);
    }
  };

  getstadistic_invice_status = async (body: any = {}) => {
    const headers = { 'content-type': 'application/json' };
    const request = {};
    try {
      return lastValueFrom(
        this.httpClient.post<any>(
          `${this.baseUrl}/api/invoice-credit/stadistic_invice_status`,
          body,
          { headers: headers, params: request }
        )
      );
    } catch (error) {
      console.error(error);
    }
  };

  getRegister_payment = async (body: any) => {
    const headers = {};
    const request = {};
    try {
      const resp = lastValueFrom(
        this.httpClient.post<any>(
          `${this.baseUrl}/api/invoice-credit/register_payment`,
          body,
          { headers: headers, params: request }
        )
      );
      return resp;
    } catch (error) {
      console.error(error);
    }
  };

  getUpdate_payment = async (body: any) => {
    const headers = {};
    const request = {};
    try {
      const resp = lastValueFrom(
        this.httpClient.post<any>(
          `${this.baseUrl}/api/invoice-credit/update_payment`,
          body,
          { headers: headers, params: request }
        )
      );
      return resp;
    } catch (error) {
      console.error(error);
    }
  };

  setPersonBiometria = async (body: any) => {
    const headers = {};
    const request = {};
    try {
      return lastValueFrom(
        this.httpClient.post<any>(
          `${this.baseUrl}/api/person/biometria`,
          body,
          { headers: headers, params: request }
        )
      );
    } catch (error) {
      console.error(error);
    }
  };

  getShowValidateTel = async () => {
    return this.showValidateTel$;
  };

  setTlfValidates(tlfValidates: any) {
    this.tlfValidates$.next(tlfValidates);
  }

  setNav(nav: any) {
    this.nav$.next(nav);
  }

  setTake(take: any) {
    this.take$.next(take);
  }

  setBiometria(biometria: any) {
    this.biometria$.next(biometria);
  }

  setShowValidateTel(showValidateTel: any) {
    this.showValidateTel$.next(showValidateTel);
  }

  getRate = async (body: any = {}) => {
    const headers = { 'content-type': 'application/json' };
    const request = {};
    try {
      return lastValueFrom(
        this.httpClient.post<any>(
          `${this.baseUrl}/api/invoice-credit/rate`,
          body,
          { headers: headers, params: request }
        )
      );
    } catch (error) {
      console.error(error);
    }
  };

  activeCupo = async (body: any) => {
    const headers = { 'content-type': 'application/json' };
    const request = {};
    try {
      const resp = lastValueFrom(
        this.httpClient.post<any>(
          `${this.baseUrl}/api/buro/active_cupo`,
          body,
          { headers: headers, params: request }
        )
      );
      return resp;
    } catch (error) {
      console.error(error);
    }
  };

  manual_approve = async (body: any) => {
    const headers = { 'content-type': 'application/json' };
    const request = {};
    try {
      const resp = lastValueFrom(
        this.httpClient.post<any>(
          `${this.baseUrl}/api/approve/manual_approve`,
          body,
          { headers: headers, params: request }
        )
      );
      return resp;
    } catch (error) {
      console.error(error);
    }
  };

  getDeduction_payment = async (body: any = {}) => {
    const headers = { 'content-type': 'application/json' };
    const request = {};
    try {
      return lastValueFrom(
        this.httpClient.post<any>(
          `${this.baseUrl}/api/invoice-credit/deduction_payment`,
          body,
          { headers: headers, params: request }
        )
      );
    } catch (error) {
      console.error(error);
    }
  };

  send_whatsapp = async (body: any) => {
    const headers = { 'content-type': 'application/json' };
    const request = {};
    try {
      return lastValueFrom(
        this.httpClient.post<any>(
          `${this.baseUrl}/api/integration/send_whatsapp`,
          body,
          { headers: headers, params: request }
        )
      );
    } catch (error) {
      console.error(error);
    }
  };

  installMentNextCuote = async (body: any) => {
    const headers = { 'content-type': 'application/json' };
    const request = {};

    try {
      return lastValueFrom(
        this.httpClient.post<any>(
          `${this.baseUrl}/api/integration/installMent_next_cuote`,
          body,
          { headers: headers, params: request }
        )
      );
    } catch (error) {
      console.error(error);
    }
  };

  invitarCompraAll = async (body: any) => {
    const headers = { 'content-type': 'application/json' };
    const request = {};

    try {
      return lastValueFrom(
        this.httpClient.post<any>(
          `${this.baseUrl}/api/integration/invitacion_compra_all`,
          body,
          { headers: headers, params: request }
        )
      );
    } catch (error) {
      console.error(error);
    }
  };

  send_notification = async (body: any) => {
    const headers = {};
    const request = {};

    try {
      return lastValueFrom(
        this.httpClient.post<any>(
          `${this.baseUrl}/api/integration/send_notification`,
          body,
          { headers: headers, params: request }
        )
      );
    } catch (error) {
      console.error(error);
    }
  };

  bulk_client_upload = async (body: any) => {
    const headers = {};
    const request = {};

    try {
      return lastValueFrom(
        this.httpClient.post<any>(
          `${this.baseUrl}/api/integration/bulk_client_upload`,
          body,
          { headers: headers, params: request }
        )
      );
    } catch (error) {
      console.error(error);
    }
  };

  bulk_approved_upload = async (body: any) => {
    const headers = {};
    const request = {};

    try {
      return lastValueFrom(
        this.httpClient.post<any>(
          `${this.baseUrl}/api/integration/bulk_approved_upload`,
          body,
          { headers: headers, params: request }
        )
      );
    } catch (error) {
      console.error(error);
    }
  };

  status_user = async (body: any) => {
    const headers = { 'content-type': 'application/json' };
    const request = {};
    try {
      return lastValueFrom(
        this.httpClient.post<any>(
          `${this.baseUrl}/api/person/status_user`,
          body,
          { headers: headers, params: request }
        )
      );
    } catch (error) {
      console.error(error);
    }
  };

  status_quote_user = async (body: any) => {
    const headers = { 'content-type': 'application/json' };
    const request = {};

    try {
      return lastValueFrom(
        this.httpClient.post<any>(
          `${this.baseUrl}/api/person/toogle_status_quote_user`,
          body,
          { headers: headers, params: request }
        )
      );
    } catch (error) {
      console.error(error);
    }
  };

  toogleStatusClient = async (body: any) => {
    const headers = { 'content-type': 'application/json' };
    const request = {};
    try {
      return lastValueFrom(
        this.httpClient.post<any>(
          `${this.baseUrl}/api/person/toogle_client_status`,
          body,
          { headers: headers, params: request }
        )
      );
    } catch (error) {
      console.error(error);
    }
  };

  shop_user = async (body: any) => {
    const headers = { 'content-type': 'application/json' };
    const request = {};
    try {
      return lastValueFrom(
        this.httpClient.post<any>(
          `${this.baseUrl}/api/person/shop_user`,
          body,
          { headers: headers, params: request }
        )
      );
    } catch (error) {
      console.error(error);
    }
  };

  profile_user = async (body: any) => {
    const headers = { 'content-type': 'application/json' };
    const request = {};
    try {
      return lastValueFrom(
        this.httpClient.post<any>(
          `${this.baseUrl}/api/person/profile_user`,
          body,
          { headers: headers, params: request }
        )
      );
    } catch (error) {
      console.error(error);
    }
  };

  pass_user = async (body: any) => {
    const headers = { 'content-type': 'application/json' };
    const request = {};
    try {
      return lastValueFrom(
        this.httpClient.post<any>(
          `${this.baseUrl}/api/person/pass_user`,
          body,
          { headers: headers, params: request }
        )
      );
    } catch (error) {
      console.error(error);
    }
  };

  getBanks = async (params: any = {}) => {
    const headers = { 'content-type': 'application/json' };
    const request = params;
    try {
      return lastValueFrom(
        this.httpClient.get<any>(this.baseUrl + '/api/invoice-credit/banks', {
          headers: headers,
          params: request,
        })
      );
    } catch (error) {
      console.error(error);
    }
  };

  getPaymentMethodCustomer = async (id: any = {}) => {
    const headers = { 'content-type': 'application/json' };
    try {
      return lastValueFrom(
        this.httpClient.get<any>(
          this.baseUrl + '/api/payment_method/customerById?id=' + id,
          { headers: headers, params: {} }
        )
      );
    } catch (error) {
      console.error(error);
    }
  };

  getAllies = async (params: any = {}) => {
    const headers = { 'content-type': 'application/json' };
    const request = params;
    try {
      return lastValueFrom(
        this.httpClient.get<any>(
          this.baseUrl + '/api/invoice-credit/alliesDirectDebit',
          { headers: headers, params: request }
        )
      );
    } catch (error) {
      console.error(error);
    }
  };

  getBanksWithDirectDebitEnabled = async (params: any = {}) => {
    const headers = { 'content-type': 'application/json' };
    const request = params;
    try {
      return lastValueFrom(
        this.httpClient.get<any>(
          this.baseUrl + '/api/invoice-credit/banksDirectDebit',
          { headers: headers, params: request }
        )
      );
    } catch (error) {
      console.error(error);
    }
  };

  getRemanentInvoice = async (body: any) => {
    const headers = { 'content-type': 'application/json' };
    const request = {};
    try {
      return lastValueFrom(
        this.httpClient.post<any>(
          `${this.baseUrl}/api/invoice-credit/installment_with_remainder`,
          body,
          { headers: headers, params: request }
        )
      );
    } catch (error) {
      console.error(error);
    }
  };

  generatePagare = async (body: any) => {
    const headers = { 'content-type': 'application/json' };
    const request = {};
    try {
      const resp = lastValueFrom(
        this.httpClient.post<any>(
          `${this.baseUrl}/api/invoice-credit/generate_pagare`,
          body,
          { headers: headers, params: request }
        )
      );
      return resp;
    } catch (error) {
      console.error(error);
    }
  };

  getHistoryRates = async (params: any = {}) => {
    const headers = { 'content-type': 'application/json' };
    const request = params;

    try {
      return lastValueFrom(
        this.httpClient.get<any>(
          this.baseUrl + '/api/invoice-credit/history_rates',
          { headers: headers, params: request }
        )
      );
    } catch (error) {
      console.error(error);
    }
  };

  setHistoryRate = async (body: any) => {
    const headers = { 'content-type': 'application/json' };
    const request = {};
    try {
      const resp = lastValueFrom(
        this.httpClient.post<any>(
          `${this.baseUrl}/api/invoice-credit/save_history_rate`,
          body,
          { headers: headers, params: request }
        )
      );
      return resp;
    } catch (error) {
      console.error(error);
    }
  };

  createPlan = async (body: any) => {
    const headers = { 'content-type': 'application/json' };
    const request = {};
    try {
      return lastValueFrom(
        this.httpClient.post<any>(
          `${this.baseUrl}/api/invoice-credit/plan`,
          body,
          { headers: headers, params: request }
        )
      );
    } catch (error) {
      console.error(error);
    }
  };

  deletePlan = async (body: any) => {
    const headers = { 'content-type': 'application/json' };
    const request = {};
    try {
      return lastValueFrom(
        this.httpClient.post<any>(
          `${this.baseUrl}/api/invoice-credit/d-plan`,
          body,
          { headers: headers, params: request }
        )
      );
    } catch (error) {
      console.error(error);
    }
  };

  updateCustomerPlan = async (
    code: string,
    planId: string,
    action: 'add' | 'remove',
    id_ejecutive: string
  ) => {
    const headers = { 'Content-Type': 'application/json' };

    try {
      const response = await lastValueFrom(
        this.httpClient.put<any>(
          `${this.baseUrl}/api/person/update_customer_plans?code=${code}`, 
          { code, planId, action, id_ejecutive }, // Payload containing both code and planId
          { headers } // Headers configuration
        )
      );
      return response.customerPlan; // Return the updated customer plan from the response
    } catch (error) {
      console.error('Error updating customer plan:', error);
      throw error; 
    }
  };

  getAnalysis = async (request: any) => {
    const headers = { 'content-type': 'application/json' };
    const body = {};
    try {
      return lastValueFrom(
        this.httpClient.get<any>(
          `${this.baseUrl}/api/approve/get_approve_history`,
          { headers: headers, params: request }
        )
      );
    } catch (error) {
      console.error(error);
    }
  };


getInReview = async (request: any) => {
  const headers = { 'content-type': 'application/json' };
  const body = {};
  try {
    return lastValueFrom(
      this.httpClient.get<any>(
        `${this.baseUrl}/api/invoice-credit/in-review`,
        { headers: headers, params: request }
      )
    );
  } catch (error) {
    console.error(error);
  }
};

  approve_type_person = async (request: any) => {
    const headers = { 'content-type': 'application/json' }
    const body = {};
    try {
  
      return lastValueFrom(this.httpClient.get<any>(`${this.baseUrl}/api/approve/approve_type_person`, { headers: headers, params: request }));
  
    } catch (error) {
  
      console.error(error);
  
    }
  }
  
  setManual_verification = async (body: any) => {
    const headers = { 'content-type': 'application/json' };
    const request = {};
    try {
      return lastValueFrom(
        this.httpClient.post<any>(
          `${this.url_OB}/api/biometrics/manual_verification`,
          body,
          { headers: headers, params: request }
        )
      );
    } catch (error) {
      console.error(error);
    }
  };

  setAnalysis = async (body: any) => {
    const headers = { 'content-type': 'application/json' };
    const request = {};
    try {
      return lastValueFrom(
        this.httpClient.post<any>(
          `${this.baseUrl}/api/approve/creditotal_automatic_approve`,
          body,
          { headers: headers, params: request }
        )
      );
    } catch (error) {
      console.error(error);
    }
  };

  update_quote_person = async (body: any) => {
    const headers = { 'content-type': 'application/json' };
    const request = {};
    try {
      return lastValueFrom(
        this.httpClient.post<any>(
          `${this.baseUrl}/api/person/update_quote_person`,
          body,
          { headers: headers, params: request }
        )
      );
    } catch (error) {
      console.error(error);
    }
  };

  activePlan = async (body: any) => {
    const headers = { 'content-type': 'application/json' };
    const request = {};
    try {
      return lastValueFrom(
        this.httpClient.post<any>(
          `${this.baseUrl}/api/invoice-credit/a-plan`,
          body,
          { headers: headers, params: request }
        )
      );
    } catch (error) {
      console.error(error);
    }
  };

  getplans = async (params: any) => {
    const headers = { 'content-type': 'application/json' };
    const request = params;

    try {
      return lastValueFrom(
        this.httpClient.get<any>(this.baseUrl + '/api/invoice-credit/plans', {
          headers: headers,
          params: request,
        })
      );
    } catch (error) {
      console.error(error);
    }
  };

  getCustomerPlans = async (code: string) => {
    const headers = { 'content-type': 'application/json' };

    try {
      const response = await lastValueFrom(
        this.httpClient.get<any>(
          `${this.baseUrl}/api/person/customer_plans?code=${code}`,
          {
            headers: headers,
          }
        )
      );
      return response.plans || [];
    } catch (error) {
      console.error('Error fetching customer plans:', error);
      throw error;
    }
  };

  updateDefaultPlan = async (code: string, planId: string) => {
    const headers = { 'content-type': 'application/json' };

    try {
      const response = await lastValueFrom(
        this.httpClient.put<any>(
          `${this.baseUrl}/api/person/update_default_plan`,
          { code, planId },
          { headers }
        )
      );
      return response;
    } catch (error) {
      console.error('Error updating default plan:', error);

      // Re-throw the error or return a rejected promise so it propagates correctly
      return Promise.reject(error);
    }
  };


  payment_sub_statusPost = async (body: any) => {
    const headers = { 'content-type': 'application/json' };
    const request = {};
    try {
      return lastValueFrom(
        this.httpClient.post<any>(
          `${this.baseUrl}/api/invoice-credit/payment_sub_status`,
          body,
          { headers: headers, params: request }
        )
      );
    } catch (error) {
      console.error(error);
    }
  };

  customerDirectDebitProcesses = async (
    codeIdentity: string,
    identity: string
  ) => {
    const headers = { 'content-type': 'application/json' };
    try {
      return lastValueFrom(
        this.httpClient.get<any>(
          this.baseUrl +
            '/api/direct_debit/direct_debit_status_client?identity=' +
            identity +
            '&codeIdentity=' +
            codeIdentity,
          { headers: headers, params: {} }
        )
      );
    } catch (error) {
      console.error(error);
    }
  };

  anularCompra = async (request: any) => {
    const headers = { 'content-type': 'application/json' };
    const body = {};
    try {
      const resp = lastValueFrom(
        this.httpClient.post<any>(
          `${this.baseUrl}/api/invoice-credit/pending_to_trash`,
          body,
          { headers: headers, params: request }
        )
      );

      // const resp = lastValueFrom(this.httpClient.post<any>(`${this.baseUrl}/api/invoice-credit/reset-invoice-all`, body, { headers: headers, params: request }));
      return resp;
    } catch (error) {
      console.error(error);
    }
  };

  update_person = async (body: any) => {
    const headers = { 'content-type': 'application/json' };
    const request = {};
    try {
      return lastValueFrom(
        this.httpClient.post<any>(
          `${this.baseUrl}/api/person/update_data_base`,
          body,
          { headers: headers, params: request }
        )
      );
    } catch (error) {
      console.error(error);
    }
  };
  biometrics_data = async (request: any) => {
    const headers = { 'content-type': 'application/json' }
    const body = {};
    try {
  
      return lastValueFrom(this.httpClient.get<any>(`${this.url_OB}/api/biometrics/data`, { headers: headers, params: request }));
  
    } catch (error) {
  
      console.error(error);
  
    }
  }
  



  biometrics_history = async (request: any) => {
    const headers = { 'content-type': 'application/json' }
    const body = {};
    try {
  
      return lastValueFrom(this.httpClient.get<any>(`${this.url_TRUSTX}/api/bitacora`, { headers: headers, params: request }));
  
    } catch (error) {
  
      console.error(error);
  
    }
  }

  updateVerificationStatus = async (account_payment: string,typeConfirm: string) => {

    const headers = { 'Content-Type': 'application/json' };

    try {
      const response = await lastValueFrom(
        this.httpClient.put<any>(
          `${this.baseUrl}/api/payment_method/change_status_verification_auto`, 
          { account_payment, typeConfirm }, 
          { headers } 
        )
      );
      return response; 
    } catch (error) {
      console.error('Error updating validation:', error);
      throw error; 
    }
  };

  updateAccountStatus = async (account_payment: string, showClient: boolean) => {

    const headers = { 'Content-Type': 'application/json' };

    try {
      const response = await lastValueFrom(
        this.httpClient.put<any>(
          `${this.baseUrl}/api/payment_method/update_account_visibility`, 
          { account_payment, showClient }, 
          { headers } 
        )
      );
      return response; 
    } catch (error) {
      console.error('Error updating validation:', error);
      throw error; 
    }
  };
  

  clientStadistic = async (params: any) => {
    const headers = { 'content-type': 'application/json' };
    const request = params;

    try {
      return lastValueFrom(
        this.httpClient.get<any>(
          this.baseUrl + '/api/invoice-credit/client-stadictic',
          { headers: headers, params: request }
        )
      );
    } catch (error) {
      console.error(error);
    }
  };
  
}
